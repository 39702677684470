.App {
  text-align: center;
}

.App-container{
  width: 100%;
}

.App-header{
  color:#FFF;
  padding-left: 2%;
  padding-right: 2%;
  /*margin-left: 0px;*/
  /*background-color: #FFF;*/
}

.App-footer{
  text-align: center;
  background-color: #132d46;/*#1a0427;*/
  color: #FFF;
  position:fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.App-content{
  padding: 20px;
  height: 80vh;
  /*width: 85vw;*/
  margin-left: 0px;
  background-color: #f8f8f8;/*#F5F5DC;*/
}

@media only screen and (max-width: 500px) {
  .App-footer{
    text-align: center;
    background-color: #132d46;
    color: #FFF;
    position:fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-top: 0%;
    height: 40px;
  }
}

.r1{
  background-color: aqua;
}

.r2{
  background-color: #01c38e;/*#feaf5f;*//*#001529;*/ /*orange;*/
}

.homeEnDesarrollo{
  width: 35vw;
  height: auto;
  margin-left: 30vw;
}

.componentsContainer{
  border: 2px outset #01c38e;
  border-radius: 15px;
  padding-top: 2vw;
  /*padding-bottom: 2vw;*//**/
  padding-left: 2vw;
  padding-right: 2vw;
}

.componentsContainerDetCompras{
  border: 2px outset #01c38e;
  border-radius: 15px;
  padding-top: 2vw;
  padding-bottom: 2vw;
  padding-left: 2vw;
  padding-right: 2vw;
}

.subtituloTablaDetalleCompras{
  text-align: center;
}

.tituloModalRegistrarCompra{
  text-align: center;
}
/*.buscador{
  width: 32vw;
}*/

@media only screen and (min-width: 575px) {
  .buscador{
    width: 94vw;
  }
}

@media only screen and (min-width: 638px) {
  .buscador{
    width: 650px;
  }
}

@media only screen and (min-width: 664px) {
  .buscador{
    width: 660px;
  }
}