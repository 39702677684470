
.subtituloTabla {
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 4vh;
  }

  .App-modal-confirmación-texto{
    text-align: center;
  }

/*TABLA*/
  /*Reduciendo pantalla maximizada*/
  @media only screen and (max-width: 1260px){
    .tabla{
      /*min-width: 800px;*/
      overflow-x: auto;
      max-height: 600px;
      max-width: 1120px;/*700px;*/
    }
  }

  @media only screen and (max-width: 800px/*800px*/) {
    .tabla{
      /*min-width: 800px;*/
      overflow-x: auto;
      max-height: 500px;
      max-width: 800px;/*700px;*/
    }
  }


  @media only screen and (max-width: 800px/*800px*/) and (max-height:400px) {
    .tabla{
      /*min-width: 800px;*/
      overflow-x: auto;
      max-height: 150px;
      max-width: 800px;/*700px;*/
    }
  }


  /*Tablet Ipad hechado horizontal*/
  @media only screen and (max-width: 1080px) {
    .tabla{
      /*min-width: 800px;*/
      overflow-x: auto;
      max-height: 500px;
      max-width: 1000px;/*700px;*/
    }
  }

  /*Tablet Ipad vertical parado*/
  @media only screen and (max-width: 811px) and (max-height:1080px) {
    .tabla{
      /*min-width: 800px;*/
      overflow-x: auto;
      max-height: 750px;
      max-width: 800px;/*700px;*/
    }
  }

   /*Galaxy s10 parado (vertical)*/
  @media only screen and (max-width: 360px) {
    .tabla{
      /*min-width: 800px;*/
      overflow-x: auto;
      max-height: 490px;
      max-width: 800px;/*700px;*/
    }
  }

  /*Galaxy s10 hechado (horizontal)*/
  @media only screen and (max-width: 761px) and (max-height:361px){
    .tabla{
      /*min-width: 800px;*/
      overflow-x: auto;
      max-height: 160px;
      max-width: 800px;/*700px;*/
    }
  }


  .tabla th{
    background-color: #FFA500 !important;
    border: 1px solid white !important;
    color: white !important;
    font-style: normal !important;
    text-align: center !important;
    
  }
  
  .tabla tr:nth-child(even) td {
    border: 1px solid silver !important; 
    text-align: center !important;
    background-color: rgb(232, 228, 228) !important;
  } 

  .tabla tr:nth-child(odd) td {
    border: 1px solid lightgrey !important; 
    text-align: center !important;
    background-color: white !important;
  } 

  .tabla tr:hover td{
    background-color: #dff6ef!important;
  } 
