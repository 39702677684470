/* .c1{
    background-color: aquamarine;
}

.c2{
    background-color: blueviolet;
} */

.nombreArchivoSubido{
  width: 31vw; /* Ancho máximo del contenedor */
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  overflow: hidden; /* Oculta el texto que excede el ancho máximo */
  text-overflow: ellipsis; /* Agrega puntos suspensivos para indicar que se ha truncado el texto */
  color: #1677FF;
  cursor: pointer;
}

.botonRegistrar {
  width: 10vw;
  margin-left: 10vw;
}

.botonCancelar {
  width: 10vw;
  margin-left: 2vw;
}


.tituloRegistrar {
  /* margin-left: 10vw; */
  font-family: 'Montserrat', sans-serif;
}



.containerForm {
  border: 2px outset #01c38e;
  border-radius: 15px;
  padding-top: 1vw;
  padding-bottom: 1vw;
  /*background-color: aquamarine;*/

}

@media only screen and (max-width: 361px) {
  .containerForm {
    /*min-width: 800px;*/
    overflow-x: auto;
    max-height: 400px;
    /*max-width: 820px;/*700px;*/
  }

  .entradaDatos {
    width: 280px;
  }

  .entradaDatosCategoria {
    width: 280px;
  }

  .campoDescripcion {
    resize: 'none';
    width: 280px;
  }

  .botonRegistrar {
    width: 25vw;
    margin-left: 5vw;
  }

  .botonCancelar {
    width: 25vw;
    margin-left: 4vw;
  }
}

@media only screen and (max-width: 360px) and (max-height:760px) {
  .containerForm {
    /*min-width: 800px;*/
    overflow-x: auto;
    max-height: 500px;
    /*max-width: 820px;/*700px;*/
  }

  .entradaDatos {
    width: 280px;
  }

  .entradaDatosCategoria {
    width: 280px;
  }

  .campoDescripcion {
    resize: 'none';
    width: 280px;
  }

  .botonRegistrar {
    width: 25vw;
    margin-left: 5vw;
  }

  .botonCancelar {
    width: 25vw;
    margin-left: 4vw;
  }

}

@media only screen and (max-width: 761px) and (max-height:361px) and (orientation:landscape) {
  .containerForm {
    /*min-width: 800px;*/
    overflow-x: auto;
    max-height: 140px;
    /*max-width: 820px;/*700px;*/
  }

  .entradaDatos {
    width: 580px;
  }

  .entradaDatosCategoria {
    width: 700px;
  }

  .campoDescripcion {
    resize: 'none';
    width: 580px;
  }

  .botonRegistrar {
    width: 25vw;
    margin-left: 5vw;
  }

  .botonCancelar {
    width: 25vw;
    margin-left: 4vw;
  }
}

@media only screen and (min-width: 361px) and (max-width: 811px) and (orientation:portrait) {
  .containerForm {
    /*min-width: 800px;*/
    overflow-x: auto;
    max-height: 640px;
    /*max-width: 820px;/*700px;*/
  }

  .entradaDatos {
    width: 580px;
  }

  .entradaDatosCategoria {
    width: 700px;
  }

  .campoDescripcion {
    resize: 'none';
    width: 580px;
  }

  .botonRegistrar {
    width: 25vw;
    margin-left: 24vw;
  }

  .botonCancelar {
    width: 25vw;
    margin-left: 4vw;
  }
}

@media only screen and (min-width: 761px) and (max-width: 1081px) and (max-height:811px) {
  .containerForm {
    overflow-x: auto;
    max-height: 640px;
  }

  .entradaDatos {
    width: 410px;
  }

  .entradaDatosCategoria {
    width: 490px;
  }

  .campoDescripcion {
    resize: 'none';
    width: 490px;
  }

  .botonRegistrar {
    width: 25vw;
    margin-left: 24vw;
  }

  .botonCancelar {
    width: 25vw;
    margin-left: 4vw;
  }
}

@media only screen and (min-width: 761px) and (max-width: 1081px) and (max-height:811px) {
  .containerForm {
    overflow-x: auto;
    max-height: 640px;
  }

  .entradaDatos {
    width: 410px;
  }

  .entradaDatosCategoria {
    width: 490px;
  }

  .campoDescripcion {
    resize: 'none';
    width: 490px;
  }

  .botonRegistrar {
    width: 25vw;
    margin-left: 24vw;
  }

  .botonCancelar {
    width: 25vw;
    margin-left: 4vw;
  }
}

/*Este afecta a tablet landscape*/
@media only screen and (min-height: 361px) and (max-height: 600px) and (orientation:landscape) {
  .containerForm {
    overflow-x: auto;
    max-height: 200px;
  }

  .entradaDatos {
    width: 650px;
  }

  .entradaDatosCategoria {
    width: 800px;
  }

  .campoDescripcion {
    resize: 'none';
    width: 650px;
  }

  .botonRegistrar {
    width: 25vw;
    margin-left: 24vw;
  }

  .botonCancelar {
    width: 25vw;
    margin-left: 4vw;
  }
}

@media only screen and (max-height: 361px) and (orientation:landscape) {
  .containerForm {
    overflow-x: auto;
    max-height: 170px;
  }

  .entradaDatos {
    width: 450px;
  }

  .entradaDatosCategoria {
    width: 600px;
  }

  .campoDescripcion {
    resize: 'none';
    width: 450px;
  }

  .botonRegistrar {
    width: 25vw;
    margin-left: 24vw;
  }

  .botonCancelar {
    width: 25vw;
    margin-left: 4vw;
  }
}

/*@media only screen and (min-height: 809px) and (max-height: 810px) and (min-width: 1078px) and (max-width: 1080px) and (orientation:landscape){
    .containerForm{
        overflow-x: auto;
        max-height: 200px;
      }
  
      .entradaDatos{
          width: 650px;
      }
  
      .entradaDatosCategoria{
          width: 800px;
      }
  
      .campoDescripcion{
          resize: 'none';
          width: 650px;
      }
  
      .botonRegistrar{
          width: 25vw;
          margin-left: 24vw;
      }
  
      .botonCancelar{
          width: 25vw;
          margin-left: 4vw;
      }
  }*/